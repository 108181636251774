import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeaderIndex from "../components/header-index"
import BodyWrapper from "../components/body-wrapper"
import ButtonLink from "../components/button-link"

const buttonAreaStyle = { display: "flex", justifyContent: "center" }

const buttonStyle = { marginLeft: "1rem", marginRight: "1rem" }

const IndexPage = () => (
  <Layout>
    <SEO title="Coach & Artist" />

    <HeaderIndex rootLink={false} />
    <BodyWrapper>
      <div style={buttonAreaStyle}>
        <ButtonLink to="/coach/" style={buttonStyle}>
          Coach
        </ButtonLink>
        <ButtonLink to="/artist/" style={buttonStyle}>
          Artist
        </ButtonLink>
      </div>
    </BodyWrapper>
  </Layout>
)

export default IndexPage
