import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Logo from "../images/logo.svg"
import media from "styled-media-query"
import { Link } from "gatsby"

const BackgroundSection = ({ className, rootLink }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "nicole_black.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={data.desktop.childImageSharp.fluid}
          backgroundColor={`#f2f2f2`}
          style={{
            backgroundPositionY: "top",
          }}
        >
          {rootLink && <StyledLink to="/"></StyledLink>}
          <img src={Logo} alt="Logo Nicole Behnke" className="header-logo" />
        </BackgroundImage>
      )
    }}
  />
)

const StyledLink = styled(Link)`
  color: #375f3b;
  display: block;
  width: 100%;
  position: absolute;
  height: 100%;
`

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  max-width: 1300px;
  margin: auto;
  height: 860px;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  margin-bottom: 50px;
  transition: height linear 200ms;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  
  ${media.lessThan("1200px")`
    height: 800px;
  `}

  ${media.lessThan("1100px")`
    height: 700px;
  `}

  ${media.lessThan("1000px")`
    height: 600px;
  `}

  ${media.lessThan("900px")`
    height: 500px;
  `}

  ${media.lessThan("800px")`
    height: 450px;
  `}

  ${media.lessThan("700px")`
    height: 400px;
  `}

  ${media.lessThan("600px")`
    height: 350px;
  `}
  
  img.header-logo {
    width: 55%;
    margin-bottom: 0;
    margin-right: -15px;
  }
`

export default StyledBackgroundSection
