import styled from "styled-components"
import { Link } from "gatsby"

const ButtonLink = styled(Link)`
  background-color: #333;
  color: #fff;
  font-size: 20px;
  padding: 20px 40px;
  text-decoration: none;
  border-radius: 0px;

  &:active,
  &:hover {
    color: white;
    background-color: #000;
    text-decoration: none;
  }

  &.small {
    font-size: 14px;
    padding: 10px 20px;
  }
`

export default ButtonLink
